// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #1E9485;
$secondary: #FA9E9F;
$accent: #FFBC88;

$dark: #1D1D1D;
$dark-page : #121212;

$positive: #21BA45;
$negative: #BE575A;
$info: #31CCEC;
$warning: #F2C037;

// https://forum.quasar-framework.org/post/26913
// Generate the font pack https://google-webfonts-helper.herokuapp.com/fonts/mulish?subsets=latin
@import 'src/css/mulish.scss';

// Set the default font-family
$typography-font-family: 'Mulish', sans-serif;

// Fix font-weight values to match the imported font family weights
$text-weights: (extralight: 200, light: 300, regular: 400, medium: 500, semibold: 600, bold: 700, extrabold: 800, extrablack: 900) !default;
$button-font-weight: 600 // or 400 if you prefer thinner