//* mulish-200 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-200.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-200.woff') format('woff');
  font-display: swap;
};


//* mulish-300 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-300.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-300.woff') format('woff');
  font-display: swap;
};


//* mulish-regular - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-regular.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-regular.woff') format('woff');
  font-display: swap;
};


//* mulish-500 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-500.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-500.woff') format('woff');
  font-display: swap;
};


//* mulish-600 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-600.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-600.woff') format('woff');
  font-display: swap;
};


//* mulish-700 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-700.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-700.woff') format('woff');
  font-display: swap;
};


//* mulish-900 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-900.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-900.woff') format('woff');
  font-display: swap;
};


//* mulish-800 - latin */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-800.woff2') format('woff2'), url('src/assets/theme/fonts/mulish-v3-latin/mulish-v3-latin-800.woff') format('woff');
  font-display: swap;
};